/*
 SignaturePad: A jQuery plugin for assisting in the creation of an HTML5 canvas
 based signature pad. Records the drawn signature in JSON for later regeneration.

 Dependencies: FlashCanvas/1.5, json2.js, jQuery/1.3.2+

 @project ca.thomasjbradley.applications.signaturepad
 @author Thomas J Bradley <hey@thomasjbradley.ca>
 @link http://thomasjbradley.ca/lab/signature-pad
 @link http://github.com/thomasjbradley/signature-pad
 @copyright Copyright MMXI, Thomas J Bradley
 @license New BSD License
 @version 2.3.0
*/
(function(c){function C(n,m){function r(a,e){var f=c(a.target).offset(),g;clearTimeout(p);p=false;if(typeof a.changedTouches!=="undefined"){g=Math.floor(a.changedTouches[0].pageX-f.left);f=Math.floor(a.changedTouches[0].pageY-f.top)}else{g=Math.floor(a.pageX-f.left);f=Math.floor(a.pageY-f.top)}if(j.x===g&&j.y===f)return true;if(j.x===null)j.x=g;if(j.y===null)j.y=f;if(e)f+=e;h.beginPath();h.moveTo(j.x,j.y);h.lineTo(g,f);h.lineCap=b.penCap;h.stroke();h.closePath();l.push({lx:g,ly:f,mx:j.x,my:j.y});
j.x=g;j.y=f}function o(){s?i.each(function(){this.removeEventListener("touchmove",r)}):i.unbind("mousemove.signaturepad");j.x=null;j.y=null;b.output&&l.length>0&&c(b.output,d).val(JSON.stringify(l))}function t(){o();h.clearRect(0,0,k.width,k.height);h.fillStyle=b.bgColour;h.fillRect(0,0,k.width,k.height);if(!b.displayOnly)if(b.lineWidth){h.beginPath();h.lineWidth=b.lineWidth;h.strokeStyle=b.lineColour;h.moveTo(b.lineMargin,b.lineTop);h.lineTo(k.width-b.lineMargin,b.lineTop);h.stroke();h.closePath()}h.lineWidth=
b.penWidth;h.strokeStyle=b.penColour;c(b.output,d).val("");l=[]}function x(a){s?i.each(function(){this.addEventListener("touchmove",r,false)}):i.bind("mousemove.signaturepad",r);r(a,1)}function D(){u=false;i.each(function(){if(this.removeEventListener){this.removeEventListener("touchend",o);this.removeEventListener("touchcancel",o);this.removeEventListener("touchmove",r)}if(this.ontouchstart)this.ontouchstart=null});i.unbind("mousedown.signaturepad");i.unbind("mouseup.signaturepad");i.unbind("mousemove.signaturepad");
i.unbind("mouseleave.signaturepad");c(b.clear,d).unbind("click.signaturepad")}function y(a){if(u)return false;u=true;c("input").blur();if(typeof a.changedTouches!=="undefined")s=true;if(s){i.each(function(){this.addEventListener("touchend",o,false);this.addEventListener("touchcancel",o,false)});i.unbind("mousedown.signaturepad")}else{i.bind("mouseup.signaturepad",function(){o()});i.bind("mouseleave.signaturepad",function(){p||(p=setTimeout(function(){o();clearTimeout(p);p=false},500))});i.each(function(){this.ontouchstart=
null})}}function v(){c(b.typed,d).hide();t();i.each(function(){this.ontouchstart=function(a){a.preventDefault();y(a);x(a,this)}});i.bind("mousedown.signaturepad",function(a){y(a);x(a,this)});c(b.clear,d).bind("click.signaturepad",function(a){a.preventDefault();t()});c(b.typeIt,d).bind("click.signaturepad",function(a){a.preventDefault();z()});c(b.drawIt,d).unbind("click.signaturepad");c(b.drawIt,d).bind("click.signaturepad",function(a){a.preventDefault()});c(b.typeIt,d).removeClass(b.currentClass);
c(b.drawIt,d).addClass(b.currentClass);c(b.sig,d).addClass(b.currentClass);c(b.typeItDesc,d).hide();c(b.drawItDesc,d).show();c(b.clear,d).show()}function z(){t();D();c(b.typed,d).show();c(b.drawIt,d).bind("click.signaturepad",function(a){a.preventDefault();v()});c(b.typeIt,d).unbind("click.signaturepad");c(b.typeIt,d).bind("click.signaturepad",function(a){a.preventDefault()});c(b.output,d).val("");c(b.drawIt,d).removeClass(b.currentClass);c(b.typeIt,d).addClass(b.currentClass);c(b.sig,d).removeClass(b.currentClass);
c(b.drawItDesc,d).hide();c(b.clear,d).hide();c(b.typeItDesc,d).show()}function A(a){for(c(b.typed,d).html(a.replace(/>/g,"&gt;").replace(/</g,"&lt;"));c(b.typed,d).width()>k.width;){a=c(b.typed,d).css("font-size").replace(/px/,"");c(b.typed,d).css("font-size",a-1+"px")}}function E(a,e){c("p."+e.errorClass,a).remove();a.removeClass(e.errorClass);c("input, label",a).removeClass(e.errorClass)}function F(a,e,f){if(a.nameInvalid){e.prepend(['<p class="',f.errorClass,'">',f.errorMessage,"</p>"].join(""));
c(f.name,e).focus();c(f.name,e).addClass(f.errorClass);c("label[for="+c(f.name).attr("id")+"]",e).addClass(f.errorClass)}a.drawInvalid&&e.prepend(['<p class="',f.errorClass,'">',f.errorMessageDraw,"</p>"].join(""))}function w(){var a=true,e={drawInvalid:false,nameInvalid:false},f=[d,b],g=[e,d,b];b.onBeforeValidate&&typeof b.onBeforeValidate==="function"?b.onBeforeValidate.apply(q,f):E.apply(q,f);if(b.drawOnly&&l.length<1){e.drawInvalid=true;a=false}if(c(b.name,d).val()===""){e.nameInvalid=true;a=
false}b.onFormError&&typeof b.onFormError==="function"?b.onFormError.apply(q,g):F.apply(q,g);return a}function B(a,e,f){for(var g in a)if(typeof a[g]==="object"){e.beginPath();e.moveTo(a[g].mx,a[g].my);e.lineTo(a[g].lx,a[g].ly);e.lineCap=b.penCap;e.stroke();e.closePath();f&&l.push({lx:a[g].lx,ly:a[g].ly,mx:a[g].mx,my:a[g].my})}}function G(){if(parseFloat((/CPU.+OS ([0-9_]{3}).*AppleWebkit.*Mobile/i.exec(navigator.userAgent)||[0,"4_2"])[1].replace("_","."))<4.1){c.fn.Oldoffset=c.fn.offset;c.fn.offset=
function(){var a=c(this).Oldoffset();a.top-=window.scrollY;a.left-=window.scrollX;return a}}c(b.typed,d).bind("selectstart.signaturepad",function(a){return c(a.target).is(":input")});i.bind("selectstart.signaturepad",function(a){return c(a.target).is(":input")});!k.getContext&&FlashCanvas&&FlashCanvas.initElement(k);if(k.getContext){h=k.getContext("2d");c(b.sig,d).show();if(!b.displayOnly){if(!b.drawOnly){c(b.name,d).bind("keyup.signaturepad",function(){A(c(this).val())});c(b.name,d).bind("blur.signaturepad",
function(){A(c(this).val())});c(b.drawIt,d).bind("click.signaturepad",function(a){a.preventDefault();v()})}b.drawOnly||b.defaultAction==="drawIt"?v():z();if(b.validateFields)c(n).is("form")?c(n).bind("submit.signaturepad",function(){return w()}):c(n).parents("form").bind("submit.signaturepad",function(){return w()});c(b.sigNav,d).show()}}}var q=this,b=c.extend({},c.fn.signaturePad.defaults,m),d=c(n),i=c(b.canvas,d),k=i.get(0),h=null,j={x:null,y:null},l=[],p=false,s=false,u=false;c.extend(q,{init:function(){G()},
updateOptions:function(a){c.extend(b,a)},regenerate:function(a){q.clearCanvas();c(b.typed,d).hide();if(typeof a==="string")a=JSON.parse(a);B(a,h,true);b.output&&c(b.output,d).length>0&&c(b.output,d).val(JSON.stringify(l))},clearCanvas:function(){t()},getSignature:function(){return l},getSignatureString:function(){return JSON.stringify(l)},getSignatureImage:function(){var a=document.createElement("canvas"),e=null;e=null;a.style.position="absolute";a.style.top="-999em";a.width=k.width;a.height=k.height;
document.body.appendChild(a);!a.getContext&&FlashCanvas&&FlashCanvas.initElement(a);e=a.getContext("2d");e.fillStyle=b.bgColour;e.fillRect(0,0,k.width,k.height);e.lineWidth=b.penWidth;e.strokeStyle=b.penColour;B(l,e);e=a.toDataURL.apply(a,arguments);document.body.removeChild(a);return e},validateForm:function(){return w()}})}c.fn.signaturePad=function(n){var m=null;this.each(function(){if(c.data(this,"plugin-signaturePad")){m=c.data(this,"plugin-signaturePad");m.updateOptions(n)}else{m=new C(this,
n);m.init();c.data(this,"plugin-signaturePad",m)}});return m};c.fn.signaturePad.defaults={defaultAction:"typeIt",displayOnly:false,drawOnly:false,canvas:"canvas",sig:".sig",sigNav:".sigNav",bgColour:"#ffffff",penColour:"#145394",penWidth:2,penCap:"round",lineColour:"#ccc",lineWidth:0,lineMargin:5,lineTop:35,name:".name",typed:".typed",clear:".clearButton",typeIt:".typeIt a",drawIt:".drawIt a",typeItDesc:".typeItDesc",drawItDesc:".drawItDesc",output:".output",currentClass:"current",validateFields:true,
errorClass:"error",errorMessage:"Bitte Namen eintragen",errorMessageDraw:"Bitte Unterschrift hinzufuegen",onBeforeValidate:null,onFormError:null}})(jQuery);
